.dropdown-shadow {
  box-shadow: 0px 40px 120px 0px #0000001f;
}

.search-input-shadow {
  box-shadow: 0px 20px 60px -6px rgba(0, 0, 0, 0.04);
}

.img-filter-shadow {
  filter: drop-shadow(0px 0px 7.19551px rgba(0, 0, 0, 0.05));
}
