@import 'sass/main.scss';
@import 'sass/style.scss';

.bg-blue-500 {}
.btn-xs {
  @apply px-2 py-1.5 text-xs;
}
.btn-sm {
  @apply px-4 py-2;
}
.btn-md {
  @apply px-5 py-2.5;
}
.btn-lg {
  @apply px-6 py-3;
}
.btn-xl {
  @apply px-8 py-4;
}
.btn-primary {
  @apply text-white;
}
.btn-secondary {
  @apply text-slate-700 bg-transparent border-slate-700 dark:text-white dark:border-white;
}

.btn-success {
  @apply text-white;
}
.btn-warning {
  @apply text-white;
}
.btn-info {
  @apply text-white;
}
.btn-danger {
  @apply text-white;
}

#layout.base-layout {
  display: flex;
  flex-direction: column;
}
.minimize .sidemenu-item.active .child-menu {
  min-width: 250px;
}

a.sidemenu-item.active {
  display: flex;
}
.react-tooltip[role=tooltip] {
  max-width: 370px;
}

@media (max-width: 767px) {
  #layout {
    display: block;
    header {
      padding-top: 0;
      padding-bottom: 10px;
      gap: 10px;

      .user-noti {
        flex-direction: row;
        justify-content: flex-end;
        .dropdown {
          .dropdown-content > .menu {
            min-width: 250px;
            margin-top: 20px;
            margin-right: -10px;
          }
        }
      }
    }
    & > aside {
      padding-left: 0;
      padding-right: 0;
      position: fixed;
      top: 0;
      bottom: 0;
      transition: all .3s;
      transform: translateX(-100%);
      width: 80%;
      max-width: 250px;
      #sidebar-btn {
        display: none;
      }
    }
    &.active-menu > aside {
      transform: translateX(0);
      z-index: 10;
    }
    main {
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 15px;
      .breadcrumb-wrap {
        white-space: nowrap;
        width: 100%;
        padding-bottom: 10px;
        margin-bottom: 10px;
        overflow-x: clip;
        &:focus,
        &:hover {
          overflow-x: auto;
          -ms-overflow-style: initial;
          scrollbar-width: thin;
          scrollbar-color: #637284 transparent;
          -ms-overflow-style: none;
          scrollbar-width: none;
          // .breadcrumb-item {
          //   &:not(:first-child):not(:last-child) {
          //     display: flex;
          //   }
          // }
        }

        .breadcrumb-item {
          &:first-child {
            padding-right: 15px;
          }
          // &:not(:first-child):not(:last-child) {
          //   display: none;
          // }
        }
      }
    }
  }
  .theme-ctrl-wrap {
    justify-content: space-around;
    .theme-ctrl-theme-switch,
    .theme-ctrl-fullscreen {
      height: 20px;
    }
    .theme-ctrl-fullscreen {
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
    }
  }
  .base-layout {
    main > form {
      width: 100%;
    }
  }
  .react-tooltip[role=tooltip] {
    max-width: 320px;
  }
}

@media (max-width: 475px) {
  .react-tooltip[role=tooltip] {
    max-width: 90%;
  }
}
.box-copy-container {
  .box-copy-action {
    opacity: 0;
    transition: opacity 0.3s;
    margin-left: 7px;
    svg {
      width: 18px;
      height: 18px;
    }
  }
  &:hover,
  &:focus {
    .box-copy-action {
      opacity: 1;
    }
  }
}
.bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246/var(--tw-bg-opacity));
}
.bg-blue-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(219 234 254/var(--tw-bg-opacity));
}
.shipment-detail-description-list {
  margin: 0;
  padding: 0;
  word-break: break-word;

  display: flex;
  flex-wrap: wrap;

  .description-list__term {
    padding: .4rem 0;
    font-size: 14px;
    line-height: 1.43;
    border: none !important;
    font-weight: 400;
    color: #6d7175;
    flex: 0 1 38%;
  }
  .description-list__description {
    padding: .4rem 0;
    font-size: 14px;
    line-height: 1.43;
    border: none !important;
    font-weight: 400;

    max-width: 62%;
    color: #202223;
    flex: 1 1 60%;
    hyphens: auto;
    text-align: right;
  }
}
@media (min-width: 768px) {
  .shipment-detail-description-list {
    align-items: flex-start;
  }
}

.step-wrap {
  .step {
    position: relative;
    z-index: 1;
    .step-icon-wrap {
      z-index: 1;
    }
  }
  .step:not(:last-child) {
    &:after {
      content: ' ';
      position: absolute;
      top: 0;
      left: 9px;
      width: 1px;
      bottom: 0;
      z-index: 0;
      background-color: #ddd;
    }
  }
  .show-more .step-icon-wrap {

  }
}
.bg-sky-300 {
  background-color: rgb(125, 211, 252);
}

.z-50 {
  z-index: 50;
}

.z-60 {
  z-index: 60;
}

.text-error {
  color: red;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  .tag-wrap {
    background-color: rgb(228, 229, 231);
    padding: 5px 10px;
    border-radius: 3px;
    display: flex;
    .remove {
      margin-left: 10px;
    }
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}
table.table th:first-child {
  position: static;
}
