.filter-gray {
  filter: invert(52%) sepia(17%) saturate(281%) hue-rotate(201deg) brightness(94%) contrast(87%);
}

.filter-green {
  filter: invert(83%) sepia(96%) saturate(337%) hue-rotate(84deg) brightness(88%) contrast(84%);
}

.filter-color-brands {
  filter: invert(44%) sepia(39%) saturate(1171%) hue-rotate(210deg) brightness(89%) contrast(91%);
}

.filter-blue {
  filter: invert(47%) sepia(75%) saturate(5999%) hue-rotate(212deg) brightness(105%) contrast(101%);
}

.filter-green-2 {
  filter: invert(78%) sepia(105%) saturate(569%) hue-rotate(121deg) brightness(224%) contrast(84%);
}

.filter-pink {
  filter: invert(64%) sepia(59%) saturate(1144%) hue-rotate(293deg) brightness(103%) contrast(97%);
}

.filter-orange {
  filter: invert(118%) sepia(188%) saturate(4813%) hue-rotate(353deg) brightness(141%) contrast(87%);
}

.filter-state {
  filter: invert(79%) sepia(38%) saturate(1000%) hue-rotate(224deg) brightness(118%) contrast(89%);
}

.filter-white {
  filter: brightness(0) invert(1);
}
