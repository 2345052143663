input[type='range'] {
  box-sizing: border-box;
  appearance: none;
  width: 320px;
  margin: 0;
  padding: 0 2px;
  overflow: hidden;
  border: 0;
  border-radius: 1px;
  outline: none;
  background: linear-gradient(#e4e7ec, #e4e7ec) no-repeat center;
  background-size: 100% 6px;
  pointer-events: none;

  &#lower {
    border-radius: 6px !important;
    background: linear-gradient(#e4e7ec, #e4e7ec) no-repeat center;
    background-size: 100% 6px;
  }

  &#upper {
    border-radius: 6px !important;
    background: linear-gradient(to right, #e4e7ec 8%, var(--color-brands) 8% 98%, #e4e7ec 98%) no-repeat center;
    background-size: 100% 6px;
    z-index: 1;
    overflow: hidden;
  }

  &:active,
  &:focus {
    outline: none;
  }

  &::-webkit-slider-thumb {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background-color: #fff;
    position: relative;
    margin: 5px 0;
    cursor: pointer;
    appearance: none;
    pointer-events: all;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
    z-index: 9999;
  }
}

.multi-range {
  position: relative;
  height: 34px;

  input[type='range'] {
    position: absolute;

    &:nth-child(1) {
      &::-webkit-slider-thumb::before {
        background-color: var(--color-brands);
      }
    }

    &:nth-child(2) {
      background: none;

      &::-webkit-slider-thumb::before {
        background-color: var(--color-brands);
      }
    }
  }
}

